import "primereact/resources/themes/lara-light-blue/theme.css"; // NOTE theme
import "primereact/resources/primereact.css"; // NOTE core css
import "primeicons/primeicons.css"; // NOTE icons
import "primeflex/primeflex.css"; // NOTE css utility
import "./app/css/flag.css";
import "./app/css/App.css";
import "./app/css/index.css";
import "./app/css/tailwind.css";
import Theme from "./Theme/Theme";
import { AppRoutes } from "./Routes/AppRoutes";
import { HashRouter as Router } from "react-router-dom";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";
import en from "../src/i18/en-US/translations.json";

function App() {
  useEffect(() => {
    i18n.use(initReactI18next).init({
      lng: "en",
      resources: {
        en: { translations: { ...en } },
      },
      fallbackLng: "en-US",
      keySeparator: false,
      ns: ["translations"],
      defaultNS: "translations",
      interpolation: { escapeValue: false },
      react: { useSuspense: true },
    });
  }, []);

  return (
    <Theme>
      <div className="min-h-screen h-full w-full">
        <I18nextProvider i18n={i18n}>
          <Router>
            <AppRoutes />
          </Router>
        </I18nextProvider>
      </div>
    </Theme>
  );
}

export default App;
