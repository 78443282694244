import { useEffect, useState } from "react";
import DashboardLeftPanel from "./Dashboard/DashboardLeftPanel";
import { useSelector } from "react-redux";
import { getDashboardData } from "app/redux/features/dashboard";
import { RootState } from "app/store";
import { getObjectFromSessionStore, setObjectInSessionStore } from "app/utils/sessionStore";
import { getObjectFromLocalStore, setObjectInLocalStore } from "app/utils/localStore";
import { USERTYPE } from "app/utils/types";
import { ProgressSpinner } from "primereact/progressspinner";
import { makeRequest } from "app/utils/makeRequest";
import { useLocation } from "react-router-dom";
import { getAuthSwitch } from "app/redux/features/auth-switch";
import { agentProfileState } from "app/redux";
import { isEmpty } from "ramda";
import Cookies from "js-cookie";
import { getIsAgencyManagerActiveData } from "app/redux/features/isAgencyActive";

const Sidebar = () => {
  const { data } = useSelector(getDashboardData);
  const sidebarClassName = data ? "w-1/10" : "w-11/50";

  const { pathname } = useLocation();
  const propStats = getObjectFromSessionStore("proposalStats");
  const [proposalStats, setProposalStats] = useState<any>(propStats ?? {});
  const lookUpResponse = useSelector((state: RootState) => state?.contactProfileLookup);
  const { postAuthSwitchSuccess } = useSelector(getAuthSwitch);
  const { isAgencyManagerActive } = useSelector(getIsAgencyManagerActiveData);

  // required for rendering when branding changes in profile->branding
  useSelector(agentProfileState);

  const getLookUpResponse = getObjectFromSessionStore("lookUpResponse");
  let badgeCount: any = {};
  const userAccess = getObjectFromLocalStore("useraccess");
  let totalProposals = 0;
  let totalPendings = 0;

  if (userAccess?.userType === USERTYPE.ADMIN) {
    totalProposals =
      (proposalStats?.requested || 0) +
      (proposalStats?.drafts || 0) +
      (proposalStats?.pendingReview || 0) +
      (proposalStats?.pendingApproval || 0);
    totalPendings = (proposalStats?.pendingApproved || 0) + (proposalStats?.pendingInProgress || 0);
    badgeCount = {
      Proposals: totalProposals,
      Requests: proposalStats?.requested,
      Pending: totalPendings,
      Drafts: proposalStats?.drafts,
      "Pending Review": proposalStats?.pendingReview,
      "Pending Approval": proposalStats?.pendingApproval,
      "Needs Processing": proposalStats?.pendingApproved,
      "In Progress": proposalStats?.pendingInProgress,
    };
  } else if (userAccess?.userType === USERTYPE.AGENT) {
    totalProposals = (proposalStats?.requested || 0) + (proposalStats?.received || 0);
    totalPendings = proposalStats?.pendingApproved || 0;
    badgeCount = {
      Proposals: totalProposals,
      "Proposals Requested": proposalStats?.requested,
      "Proposals  Received": proposalStats?.received,
      Pending: totalPendings,
      "Active  Pending": totalPendings,
    };
  } else if (userAccess?.userType === USERTYPE.CONTACT) {
    totalProposals = proposalStats?.proposals || 0;
    totalPendings = proposalStats?.pendingApproved || 0;
    badgeCount = {
      Proposals: totalProposals,
      "Active  Proposals": totalProposals,
      Pending: totalPendings,
      "Active  Pending": totalPendings,
    };
  }

  const updateProposalStats = () => {
    makeRequest("proposalStats")
      .get(`isAgency=${isAgencyManagerActive}`, false, true)
      .then((res) => {
        setProposalStats(res.data.data ?? {});
        setObjectInSessionStore("proposalStats", res.data.data ?? {});
      });
  };

  useEffect(() => {
    updateProposalStats();
  }, [isAgencyManagerActive]);

  useEffect(() => {
    if (pathname.startsWith("/proposal")) {
      updateProposalStats();
    }
  }, [pathname, isAgencyManagerActive]);

  useEffect(() => {
    if (pathname !== "/blocked" && !isEmpty(postAuthSwitchSuccess?.data)) {
      updateProposalStats();
    }
  }, [postAuthSwitchSuccess?.data, isAgencyManagerActive]);

  useEffect(() => {
    const hostName = window.location.hostname;
    const firstHostName = hostName.split(".")[0];
    const noSubDomain =
      firstHostName.startsWith("localhost") ||
      firstHostName === "dev" ||
      firstHostName === "qa" ||
      firstHostName === "iAPP";

    if (!noSubDomain) {
      const cookieData = Cookies.get("authData");
      if (cookieData) {
        const authData = JSON.parse(cookieData);
        if (authData) {
          setObjectInLocalStore("useraccess", authData);
          Cookies.remove("authData", {
            path: "/",
            domain: `.iapp.cool`,
          });
          window.location.reload();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (lookUpResponse?.data?.menu) {
      setObjectInSessionStore("lookUpResponse", lookUpResponse?.data);
    }

    let subDomain = lookUpResponse?.data?.branding?.subDomain;
    subDomain = subDomain?.split("//");
    if (subDomain && subDomain.length > 1) {
      const subDomainUrl = subDomain[1]?.replace("/", "");

      const currentDomain = window.location.hostname;

      if (!currentDomain.includes("localhost") && subDomainUrl !== currentDomain) {
        const inOneMinute = new Date(new Date().getTime() + 1 * 60 * 1000);

        Cookies.set("authData", JSON.stringify(userAccess), {
          domain: `.iapp.cool`,
          expires: inOneMinute,
        });
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
          window.location.href = lookUpResponse?.data?.branding?.subDomain;
        }, 100);
      }
    }
  }, [lookUpResponse]);

  const hasData =
    (lookUpResponse?.data?.menu || getLookUpResponse?.menu) &&
    (lookUpResponse?.data?.branding || getLookUpResponse?.branding);

  return (
    <div className={`${sidebarClassName} h-screen sidebar relative`} id="sidebar">
      {hasData ? (
        <DashboardLeftPanel
          fields={lookUpResponse?.data?.menu || getLookUpResponse?.menu}
          branding={getLookUpResponse?.branding || lookUpResponse?.data?.branding}
          badgeCount={badgeCount}
          assistantAgentList={
            lookUpResponse?.data?.assistantAgents || getLookUpResponse?.assistantAgents
          }
        />
      ) : (
        <div className="flex justify-center items-center h-full">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="4"
            fill="transparent"
            animationDuration=".5s"
            className="p-progress-circle"
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
