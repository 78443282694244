import { ReactElement, ReactNode, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { setFontSizeGlobally, setThemeGlobally } from "app/utils/utils";

interface BlockConfirmationPopUpProps {
  visible: boolean;
  footerContent?: ReactElement;
  header: string;
  content: ReactNode;
  isHeadLess?: boolean;
  style?: any;
  className?: string;
}

const BlockPopup = ({
  visible,
  footerContent = <></>,
  header,
  content,
  isHeadLess = false,
  style = { width: "80vw" },
  className = "!w-[80vw]",
}: BlockConfirmationPopUpProps) => {
  useEffect(() => {
    setTimeout(() => {
      if (visible) {
        setThemeGlobally();
        setFontSizeGlobally();
      }
    }, 10);
  }, [visible]);

  return (
    <div className="card  block-pop-up flex justify-content-center">
      <Dialog
        header={!isHeadLess ? header : null}
        visible={visible}
        closable={false}
        style={style}
        className={`${className} dark:bg-dark-primary dark:text-dark-color`}
        onHide={() => {}}
        footer={footerContent}
        keepInViewport
        contentClassName="dark:bg-dark-primary dark:text-dark-color"
        headerClassName="dark:bg-dark-primary dark:text-dark-color"
        maskClassName="dark:bg-dark-primary dark:text-dark-color"
      >
        <div className="m-0">{content}</div>
      </Dialog>
    </div>
  );
};

export default BlockPopup;
