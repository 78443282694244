import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import CustomToolTip from "./CustomToolTip";
import { getSessionStore } from "app/utils/sessionStore";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";

function ContactLeftPanelMenuItem({
  name,
  icon,
  directTo,
}: Readonly<{
  name: string;
  icon: string;
  directTo: string;
}>) {
  const { branding } = getSessionStore("lookUpResponse");
  const { secondaryColor = "" } = branding ?? {};
  const { isOpen } = useContext(ContactProfileDetailsContext);

  document.documentElement.style.setProperty("--bg-light-primary", secondaryColor);
  return (
    <li className="my-2 relative group w-full">
      <NavLink
        to={directTo}
        className={
          ({ isActive }) =>
            `flex items-center justify-center w-full h-full py-3 rounded-md  hover:!text-dark-primary
        ${isActive ? "bg-blue-500 text-white" : "hover:bg-gray-100"}`
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {({ isActive }) => (
          <div
            className={`flex gap-2 items-center justify-center !bg-transparent hover:!text-dark-primary `}
          >
            <i
              className={`pi ${icon}  ${isActive ? "text-white" : "hover:!text-dark-primary"} `}
              style={{ fontSize: "1rem" }}
            />
            {isOpen && (
              <span
                className={`text-sm font-semibold text-left ${isOpen ? "w-20" : "w-0 hidden"}  ${
                  isActive ? "text-white" : "hover:text-dark-primary"
                }
              transition-all overflow-hidden`}
              >
                {name}
              </span>
            )}
          </div>
        )}
      </NavLink>
      {!isOpen && <CustomToolTip text={name} />}
    </li>
  );
}

export default ContactLeftPanelMenuItem;
