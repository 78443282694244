import { useContext } from "react";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import CustomToolTip from "./CustomToolTip";
import ProfileSVG from "../../assets/profileAvatar.svg";

function LeftPanelImage() {
  const { contactProfilePic, isOpen } = useContext(ContactProfileDetailsContext);
  const navigate = useNavigate();
  const onSettingsClick = () => navigate("agent/contact/profile");
  return (
    <div className="py-4 flex justify-center items-center ">
      <div
        className={`${
          isOpen ? "w-20 h-20 rounded-3xl" : "!w-[3.5rem] !h-[3.5rem] rounded-lg"
        }  border-[1px] border-gray-100  relative`}
      >
        {contactProfilePic || ProfileSVG ? (
          <img
            src={contactProfilePic || ProfileSVG}
            className={`w-full h-full  object-cover ${isOpen ? "rounded-3xl" : "rounded-lg"}`}
            alt="Profile Pic"
          />
        ) : (
          <Skeleton width="5rem" height="5rem" borderRadius="8px" />
        )}
        <div
          className="absolute flex justify-center items-center bg-light-primary w-2rem shadow-lg h-8 dark:border-[1px] dark:border-colorBoder rounded-full hover:cursor-pointer hover:bg-gray-300 "
          style={{
            right: "-1rem",
            bottom: "-0.5rem",
          }}
          onClick={onSettingsClick}
        >
          <div className="relative group flex justify-center items-center">
            <i
              className="pi pi-cog hover:rotate-[10deg] text-tag-text-color"
              style={{ fontSize: "1.2rem" }}
            />
            <CustomToolTip text="Settings" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftPanelImage;
