import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import { HeaderSvgBgSring } from "app/utils/utils";
import { getSessionStore } from "app/utils/sessionStore";
import { Badge } from "primereact/badge";
import { isEmpty } from "ramda";
import { useNotificationClick } from "app/hooks/useNotificationClick";

const encodeSvg = (svg: string) => `data:image/svg+xml;base64,${btoa(svg)}`;

function ContactDashBoardHeader() {
  const navigate = useNavigate();
  const { contactFirstName, isOpen } = useContext(ContactProfileDetailsContext);
  const { branding } = getSessionStore("lookUpResponse");
  const { secondaryColor = "" } = branding ?? {};
  const showChatDot = getSessionStore("showChatDot");
  const showNotificationDot = getSessionStore("showNotificationDot");
  const onNotificationClick = useNotificationClick();

  const svgString = secondaryColor ? HeaderSvgBgSring(secondaryColor) : HeaderSvgBgSring();

  const backgroundImage = encodeSvg(svgString);
  return (
    <div
      style={{
        height: "180px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="flex-1 h-6 dark:bg-transparent" />
      <div className="h-full dark:bg-transparent" style={{ flex: isOpen ? 4 : 6 }}>
        <div className="flex justify-between items-center dark:bg-transparent h-full">
          {contactFirstName ? (
            <p
              className="text-2xl font-semibold text-black pt-3 ml-2 mt-3"
              style={{ fontFamily: "Inter" }}
            >
              {`Welcome Back, ${contactFirstName}!`}
            </p>
          ) : (
            <Skeleton width="20rem" height="3rem" borderRadius="16px" className="mb-2" />
          )}
          <div className="h-full mr-8 pt-3 dark:bg-transparent">
            <div
              className="align-self-center cursor-pointer inline rounded-full dark:!bg-[#556375]"
              onClick={() => {
                navigate("/chat");
              }}
            >
              <i className="pi pi-comments p-overlay-badge p-2 bg-headerIconsBgPrimary text-primary-bg rounded-full headerIcon hover:cursor-pointer hover:bg-opacity-85">
                <Badge
                  className={`chat-icon ${isEmpty(showChatDot) || !showChatDot ? "hidden" : ""}`}
                />
              </i>
            </div>
            <div
              onClick={onNotificationClick}
              className="dark:bg-transparent inline align-self-center cursor-pointer notification-bell-parent  ml-4 p-2 bg-headerIconsBgPrimary  rounded-full  hover:cursor-pointer dark:!bg-[#556375]"
            >
              <i className="pi pi-bell p-overlay-badge headerIcon rounded-full hover:bg-opacity-85 text-white">
                <Badge
                  className={`notification-bell ${
                    isEmpty(showNotificationDot) || !showNotificationDot ? "hidden" : ""
                  }`}
                />
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDashBoardHeader;
