import { useContext } from "react";
import { Skeleton } from "primereact/skeleton";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import CustomToolTip from "./CustomToolTip";
import ProfileSVG from "../../assets/profileAvatar.svg";

function LeftPanelUserDetails() {
  const { agentProfilePic, agentName, agentEmail, agentPhone, isOpen } = useContext(
    ContactProfileDetailsContext,
  );

  return (
    <>
      <div
        className={`py-3 px-2 border-[.5px]  rounded-lg  border-gray-300 flex  flex-col justify-center items-center ${
          isOpen ? "w-56" : "w-24"
        }`}
      >
        {isOpen && (
          <p className="text-center pb-2 mb-2 font-Inter font-semibold text-xl ">Advisor Details</p>
        )}
        <div
          className={`flex gap-1 h-full ${
            isOpen ? "justify-between w-48" : "justify-center"
          } items-center`}
        >
          {agentProfilePic || ProfileSVG ? (
            <div
              className="w-[2.5rem] h-10 rounded-full border-[1px] border-solid border-gray-500 group relative"
              style={{
                backgroundImage: `url(${agentProfilePic || ProfileSVG})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <CustomToolTip text={agentName} />
            </div>
          ) : (
            <Skeleton width="2.5rem" height="2.5rem" shape="circle" />
          )}
          {isOpen && (
            <>
              <div className="w-[2.5rem] h-10 rounded-full border-[1px] border-solid hover:bg-gray-100 border-iconRoundedBorder group relative flex justify-center items-center hover:cursor-pointer">
                <i className="pi pi-envelope text-iconRoundedBorder" />
                <CustomToolTip text={agentEmail} />
              </div>
              <div className="w-[2.5rem] h-10 rounded-full border-[1px] hover:bg-gray-100 border-solid border-iconRoundedBorder flex justify-center items-center hover:cursor-pointer group relative">
                <i className="pi pi-phone text-iconRoundedBorder" />
                <CustomToolTip text={agentPhone} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LeftPanelUserDetails;
